@import "index.scss";
@import "Home.scss";

#wrapper {
  background-color: theme-color("white");
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  color: theme-color("gray-1");

  &.center {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

#app-wrapper {
  padding-top: 4.5rem;
}

#blog-wrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 1rem;

  &:hover {
    color: theme-color("primary") !important;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.circle {
  border-radius: 50%;
  overflow: hidden;
  height: 200px;
  width: 200px;
  img {
    height: 200px;
    width: 200px;
  }
}

.Blog-header {
  background-color: theme-color("white");
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: theme-color("gray-1");
}

.home-btn {
  border-radius: 25px !important;
  width: 150px !important;
}

#tag-label {
  font-size: 10px;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  text-transform: uppercase;
  border-radius: 1rem;
  text-align: center;
  width: min-content;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.debug {
  background-color: red !important;
}

.cover-img {
  object-fit: cover !important;
  width: 100%;
  height: 250px;
}

.content-img {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.container-iframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

p {
  font-family: "Sarabun", sans-serif;
  line-height: 1.5;
}

a {
  font-family: "Sarabun", sans-serif;
  line-height: 1.5;
}

.subtitle {
  line-height: 1.5;
}

ul {
  list-style-position: outside;
}

small {
  font-family: "Sarabun", sans-serif;
  line-height: 1.5;
  &.date {
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    color: theme-color("gray-2");
    font-size: 10px;
  }
}

.footer {
  color: theme-color("gray-3");
}

hr {
  border-top: 8px;
  height: 1px;
  background-color: theme-color("gray-3");
}

.no-text-decoration {
  text-decoration: none;
  color: theme-color("font");
}

.secondary-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 1200px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 720px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 576px) {
    max-width: 720px;
  }
}
