@import "../index.scss";

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0rem 1rem;
  z-index: 999;
}

.nav-link {
  font-family: "Quicksand", sans-serif;
  color: inherit !important;
  padding: 0.8rem 1rem !important;
}
