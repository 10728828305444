.home-links {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 3rem;

  a {
    color: theme-color("gray-1");
    text-decoration: none;
    &:hover {
      color: theme-color("primary");
    }
  }
}
