@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: "Quicksand", sans-serif;
  font-weight: 700 !important;
}

h2 {
  font-family: "Quicksand", sans-serif;
  font-weight: 700 !important;
}

h3 {
  font-family: "Quicksand", sans-serif;
  font-weight: 600 !important;
}

h4 {
  font-family: "Quicksand", sans-serif;
  font-weight: 600 !important;
}

h5 {
  font-family: "Quicksand", sans-serif;
  font-weight: 600 !important;
}

h6 {
  font-family: "Quicksand", sans-serif;
  font-weight: 700 !important;
}

li {
  font-family: "Quicksand", sans-serif;
}

a {
  font-family: "Quicksand", sans-serif;
}

$theme-colors: (
  "primary": #556b2f,
  "secondary": teal,
  "font": #515151,
  "gray-1": #515151,
  "gray-2": #a7a7a7,
  "gray-3": #d3d3d3,
  "white": white,
  "black": black,
);

@for $i from 1 through 5 {
  .gap-#{$i} {
    gap: ($i * 4px);
  }
}

@function theme-color($key: "primary") {
  @return map-get($theme-colors, $key);
}

code {
  font-family: "Sarabun", sans-serif !important;
  line-height: 1.5;
  font-size: 16px !important;
}

$pagination-active-color: theme-color("white");
$pagination-active-bg: theme-color("primary");
$pagination-color: theme-color("primary");
$pagination-focus-color: theme-color("primary");
$pagination-hover-color: theme-color("primary");

@import "~bootstrap/scss/bootstrap";
